@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Raleway:wght@400;600&family=Roboto:wght@400;700&display=swap");

/* font-family: 'Montserrat', sans-serif;
font-family: 'Raleway', sans-serif;
font-family: 'Roboto', sans-serif; */

* {
  margin: 0;
  padding: 0;
}

/* =======Navbar================= */
.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 100;
  height: 65px;
  background-color: aliceblue;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 600;
  color: black;
  text-decoration: none;
}

.nav-elements ul a:hover {
  text-decoration: underline;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: aliceblue;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

/* =========Footer=========== */

.footer {
  width: 100%;
  background-color: #0e0e0e;
  color: white;
  padding: 15px;
  font-family: "Raleway", sans-serif;
}

.footer1 {
  display: flex;
  justify-content: space-evenly;
  padding: 25px 35px 25px 35px;
  color: white;
}

.footer2 {
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 8px;
}

.link-no-underline {
  text-decoration: none;
  color: inherit;
}

.link-no-underline:hover {
  color: gray;
}

.f1 {
  display: flex;
  flex-direction: column;
}

.h_f {
  font-weight: 600;
  font-size: large;
  margin-bottom: 5px;
}

.f4 {
  display: flex;
  flex-direction: column;
}

.f4a {
  display: flex;
  justify-content: space-evenly;
}

.fab {
  text-decoration: none;
  color: inherit;
  margin: 4px;
}

@media screen and (max-width: 600px) {
  .footer {
    padding: 0px;
  }
  .footer1 {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
  }

  .h_f {
    font-size: medium;
  }

  .f1,
  .f2,
  .f3,
  .f4 {
    text-align: center;
    margin: 10px;
  }

  .f4a {
    justify-content: center;
  }

  .footer2 {
    text-align: center;
    padding-bottom: 5px;
    font-size: small;
  }
}

@media screen and (max-width: 1024px) {
  .footer1 {
    padding: 20px 10px;
  }

  .h_f {
    font-size: medium;
  }
}

/* ===========Home=========== */
.home {
  background-color: aliceblue;
}

.home1,
.home3,
.home5,
.home6 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3%;
  font-family: "Roboto", sans-serif;
}

.part1 {
  padding: 15px;
  align-items: center;
}

.part2 {
  display: flex;
  justify-content: center;
  width: 50%;
}

.img {
  width: 100%;
}

.text1 {
  font-size: 2rem;
  color: #0069ec;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
}

.text2 {
  font-size: larger;
  font-weight: 400;
}

.home2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.text {
  font-size: 2rem;
  color: #0069ec;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
  padding: 15px;
}

.btn {
  margin: 10px 0px 10px 0px;
}

.home4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.box {
  display: flex;
  justify-content: space-evenly;
}

.b1 {
  display: flex;
  align-items: center;
  background-color: white;
  /* border: 1px solid black; */
  margin: 10px;
}

.industry {
  width: 220px;
  padding: 20px;
}

@media screen and (max-width: 600px) {
  .text1 {
    font-size: 1rem;
  }

  .text {
    font-size: 1.5rem;
    text-align: center;
  }

  .box {
    display: block;
  }

  .text2 {
    font-size: small;
    font-weight: 400;
  }

  .industry {
    width: 230px;
    padding: 15px;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  .box {
    display: block;
  }
}

/* =============Services==================== */

.services {
  background-color: aliceblue;
}

.card-body i {
  padding: 25px;
  color: #0069ec;
}

.p1,
.p2,
.p3 {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.carousel-inner {
  width: 100%;
  max-width: 600px;
  height: auto;
}

.heading {
  font-size: 2rem;
  color: #0069ec;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
  padding: 10px;
  text-align: center;
}

.heading2 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: large;
  padding: 10px;
  text-align: center;
}

.cards {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card {
  padding: 10px;
  margin: 10px;
  width: calc(33.33% - 20px);
}

.card:hover {
  background-color: rgb(166, 199, 228);
}

.card-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
}

.card-title {
  font-weight: 650;
}

.card-text {
  text-align: center;
  font-weight: 400;
}

@media (max-width: 768px) {
  .carousel-inner {
    max-width: 100%;
  }

  .card {
    width: calc(45% - 15px);
  }
}

@media (max-width: 576px) {
  .card {
    width: 100%;
  }
}

/* ===========Contact=============== */

.contact {
  background-color: aliceblue;
}

.contact-body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}

.c-img {
  width: 100%;
}

.c1 {
  padding: 10px;
  margin: 10px;
}

.head,
.head2 {
  font-size: 1.8rem;
  color: #0069ec;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
  padding: 10px 0;
}

.para {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

/* ========form============ */

.form {
  display: flex;
  justify-content: center;
}

.form1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 30px;
  border: 1px solid black;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  background-color: white;
}

input,
textarea {
  margin: 10px;
  padding: 5px;
  width: 100%;
}

@media (max-width: 768px) {
  .contact-body {
    flex-direction: column;
    text-align: center;
  }

  .c-img {
    width: 100%;
  }

  .form1 {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .head,
  .head2 {
    font-size: 1.5rem;
  }
}

/* ========WHY US =========== */
.why {
  background-color: aliceblue;
}

.w-body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}

.w-img {
  width: 550px;
  height: 300px;
}

.w2 {
  text-align: left;
  padding: 20px;
}

.w-heading2 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: large;
  padding: 10px;
}

@media (max-width: 600px) {
  .w-body {
    flex-direction: column;
    padding: 10px;
  }

  .w-img {
    width: 100%;
    height: auto;
  }

  .heading .w2 {
    padding: 10px;
  }

  .w-heading2 {
    font-size: medium;
    padding: 5px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .w-body {
    padding: 15px;
  }

  .w-img {
    width: 400px;
    height: 300px;
  }

  .w2 {
    text-align: left;
  }

  .w-heading2 {
    font-size: large;
    padding: 10px;
  }
}

/* ========careers=================== */
.careers {
  background-color: aliceblue;
}

.ca-para {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: larger;
  padding: 10px;
}

.ca-img {
  width: 550px;
}

.ca-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
}

.ca1 {
  font-size: 2.5rem;
  color: #0069ec;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
  padding: 10px 0;
}

@media (max-width: 600px) {
  .ca-para {
    font-size: medium;
    padding: 5px;
  }

  .ca-img {
    width: 100%;
    height: auto;
  }

  .ca-body {
    flex-direction: column;
    padding: 20px;
  }

  .ca1 {
    font-size: 2rem;
    padding: 5px 0;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .ca-para {
    font-size: larger;
    padding: 10px;
  }

  .ca-img {
    max-width: 100%;
    height: auto;
  }

  .ca-body {
    padding: 30px;
  }

  .ca1 {
    font-size: 2.5rem;
    padding: 10px 0;
  }
}

/* =============FAQs============= */

.faqs {
  background-color: aliceblue;
}

.fa1 {
  background: url("./images/gfg.webp");
  padding: 200px;
  background-size: cover;
  background-position: center;
}

.f-head {
  font-size: 2.5rem;
  color: #0069ec;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
}

.f-head2 {
  color: white;
  font-size: larger;
  text-decoration: double;
}

@media (max-width: 600px) {
  .fa1 {
    padding: 100px;
  }

  .f-head {
    font-size: 2rem;
  }

  .f-head2 {
    font-size: medium;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .fa1 {
    padding: 150px;
  }

  .f-head {
    font-size: 2.25rem;
  }
}

.q-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.q-ques {
  margin-bottom: 15px;
}

.q-head {
  font-size: 2.5rem;
  color: #0069ec;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
  justify-content: center;
}

.que,
.ans {
  padding: 5px;
  margin: 5px 15px 5px 15px;
  border: 0.5px solid gray;
  border-radius: 5px;
  background-color: rgb(196, 228, 239);
}

.que:hover {
  background-color: rgb(186, 225, 239);
  cursor: pointer;
}

.q {
  padding: 2px;
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px 30px 10px 30px;
}

@media (max-width: 768px) {
  .q-body {
    padding: 10px;
  }

  .que,
  .ans {
    margin: 5px 10px 5px 10px;
  }

  .q {
    margin: 10px;
  }
}

@media (max-width: 480px) {
  .q-head {
    font-size: 1.5rem;
  }

  .que,
  .ans {
    margin: 5px;
  }
}
